/* centered columns styles */

/* HTML markup for use

<div class="container">
    <div class="row row-centered">
        <div class="col-xs-6 col-centered"></div>
        <div class="col-xs-6 col-centered"></div>
        <div class="col-xs-3 col-centered"></div>
        <div class="col-xs-3 col-centered"></div>
        <div class="col-xs-3 col-centered"></div>
    </div>
</div>

*/

.row-centered {
    text-align:center;
}
.col-centered {
    display:inline-block;
    float:none;
    /* reset the text-align */
    text-align:left;
    /* inline-block space fix */
    margin-right:-4px;
}
.col-fixed {
    /* custom width */
    width:320px;
}
.col-min {
    /* custom min width */
    min-width:320px;
}
.col-max {
    /* custom max width */
    max-width:320px;
}

/* Google Maps image fix */

.acf-map{
    img{
        max-width: none;
    }
}

/* vertical alignment styles */

.col-top {
  vertical-align: top!important;
}
.col-middle {
  vertical-align: middle!important;
}
.col-bottom {
  vertical-align: bottom!important;
}

/* columns of same height styles */

.row-full-height {
  height: 100%;
}
.col-full-height {
  height: 100%;
  vertical-align: top;
}
.row-same-height {
  display: table;
  width: 100%;
  /* fix overflow */
  table-layout: fixed;
}
.col-xs-height {
  display: table-cell;
  float: none !important;
}

@media (min-width: 768px) {
  .col-sm-height {
    display: table-cell;
    float: none !important;
  }
}
@media (min-width: 992px) {
  .col-md-height {
    display: table-cell;
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .col-lg-height {
    display: table-cell;
    float: none !important;
  }
}