/*! _main.less */

body{
    line-height:1.6em;
    color:#666;
    font-size:1.8em;
}

img{
    max-width:100%;
    height:auto;
}

/* Bootstrap Overrides */

p{
    margin:0 0 15px 0;
}
a{
    color: @palette-01;
    &:hover{
        color: darken(@palette-01, 10%);
    }
    &:focus{
        color: darken(@palette-01, 10%);
    }
    &:visited{
        color: darken(@palette-01, 10%);
    }
}

h1,h2,h3,h4,h5,h6{
    font-family: 'EB Garamond', serif;
}

// Top Banner

@nav-height: 70px;

header.navbar-default{
    @media only screen and (min-width : 768px){
        height:@nav-height;
    }
    background-color: @palette-01;
    background-color: fade(@palette-01, 90%);
    .full-logo{
        max-width:400px;
    }
}
.navbar-brand {
  padding: 5px 0 0 15px;
  position:relative;
  z-index:2;
}
.navbar-default .navbar-toggle{
    background-color: darken(@palette-01, 5%);
    //border: 1px solid @palette-06;
    border-radius: 4px;
    .icon-bar{
        background-color: @palette-06;
    }
    &:hover{
        background-color: darken(@palette-01, 15%);
        .icon-bar{
            background-color: @white;
        }
    }
}
.navbar-nav.navbar-right:last-child {
    margin-right:0px;
}

.nav-pad{
    @media only screen and (min-width : 768px){
        position:relative;
        top:25px;
    }
}
.wrap{
    margin-top:25px;
    @media only screen and (min-width : 768px){
        margin-top:@nav-height;
    }
}
.page-header{
    margin:0px;
    padding:0px;
    border-bottom: 0px;
    .page-title h1{
        line-height:1.8em;
        border-bottom:0px;
        margin-bottom:0px;
    }
}

// Primary Navigation

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover{
    color:@caution-color;
    background-color:transparent;
    border-bottom:5px solid @caution-color;
}
.navbar-default .navbar-nav>li>a, .navbar-default .navbar-text{
    color:@white;
    @media only screen and (min-width : 768px){
        padding:0px 0px 10px 0px;
        margin:0px 0px 0px 15px;
        font-size:1.4em;
    }
}

.wp-content{
    padding-top:25px;
    padding-bottom:25px;
}
.enhance{
    font-weight:bold;
    color: @caution-color;
    letter-spacing: -2px;
    font-family: "Open Sans";
}
.posts-title{
    padding-top:25px;
    h2{
        display:inline;
    }
    a{
        padding-top:8px;
    }
}
.sidebar-active{
    .page-title{
        padding-left: 0px;
        padding-right:0px;
    }
}
.main{
    padding-bottom:30px;
}
.featured-newsletters{
    //padding:25px 0;
    background:#eee;

    .featured-newsletter{
        margin-bottom:25px;
        img{
            max-width:100%;
            min-width:100%;
            height:auto;
            margin-bottom:5px;
        }
        h3{
            a{
                color:#fff;
                &:hover,&:focus,&:visited{
                    color:#eee;
                }
            }
            padding:5px;
            text-transform: uppercase;
            font-size:1.0em;
            text-align:center;
            margin:0px;
        }
    }
    .btn{
        border-color: #bbb;
        color:#fff;
        &:hover,&:focus,&:visited{
            color:@smoke;
        }
    }
}
.newsletter-post, .standard-post{
    article{
        margin-top:25px;
        padding-top:25px;
        border-top: 25px solid;
        border-color:@palette-01;
    }
    .entry-title{
        margin-top:0px;
    }
    .post-date{
        display:block;
        background-color: @palette-01;
        color:#fff;
        text-align: center;
        margin:0px;
        padding:10px 0px;
        font-size:0.8em;
        font-weight:bold;
        text-transform: uppercase;
    }
    .thumb-container{
        text-align:center;
        img{
            margin: 0px auto 15px;
        }
    }
    .btn{
        border-color: #bbb;
        color:#fff;
        &:hover,&:focus,&:visited{
            color:@smoke;
        }
    }
}
article header{
    padding-bottom:15px;
    border-bottom:1px solid @smoke;
}
.entry-meta{
    background:@palette-01;
    color:#fff;
    margin:15px 0px;
    padding:5px 10px;
}
h1.entry-title{
    color:#43ac6a;
}
h2.entry-subhead{
    color:@caution-color;
}

.newsletter-post{
    .btn{
        &:visited{
            color:#fff;
        }
    }
}

.sidebar{
    margin-top:25px;
}
.posts-navigation{
    margin-top:0px;
    margin-bottom:0px;
    padding: 25px 0px 60px;
}
.colophon{
    padding:25px 0;
    h3{
        margin-bottom:45px;
        font-size:1.6em;
    }
    font-size:1.3em;
    a{
        color:@caution-color;
        &:hover{
            color:@snow;
        }
    }
    .menu{
        margin:0px;
        padding:0px;
        li{
            list-style: none;
            margin:0px;
            padding:0 0 20px 0;
            a{
                color:@caution-color;
                &:hover{
                    color:@snow;
                }
            }
        }
    }
    #menu-connect-colophon-menu{
        span{
            font-size:1em;
            margin: -10px 0;
        }
        .fa-circle{
            color:@tuatara;
        }
        a{
            color:@caution-color;
            &:hover{
                color:@snow;
            }
        }
        a[href*="facebook"]{
            &:hover{
                .fa-facebook{
                    color:#3B5998;
                }
            }
        }
        a[href*="twitter"]{
            &:hover{
                .fa-twitter{
                    color:#00ACED;
                }
            }
        }
        a[href*="linkedin"]{
            &:hover{
                .fa-linkedin{
                    color:#007FB1;
                }
            }
        }
        a[href*="vimeo"]{
            &:hover{
                .fa-vimeo{
                    color:#44BBFF;
                }
            }
        }
    }
}

.page-footer{
    .col-xs-12.col-sm-6.col-md-3{ // Four Columns
        @media only screen and (max-width : 991px) {
            &:nth-child(2n+3){
                clear:both;
            }
        }
        @media only screen and (min-width : 992px) {
            &:nth-child(4n+5){
                clear:both;
            }
        }
    }
}

.back-top{
    background:#333;
    border-radius:5px;
    font-size:1.6em;
    position:fixed;
    z-index:100;
    bottom:0px;
    right:3.5%;
    padding:5px 25px 10px;
    color:#fff;
    transition: all 500ms ease-in-out;
    opacity: 0;
    visibility:hidden;
    a{
        color:#ffffff;
    }
}

.back-top.visible {
    opacity: 0.75;
    bottom:10px;
    visibility: visible;
}

/* Page Image Styling */

img.alignleft{
    float:left;
    margin:0 10px 10px 0;
}

img.alignright{
    float:right;
    margin:0 0 10px 10px;
}

.search-form .input-group, .search-form .search-submit{
    width:100%;
}

.alert-default {
    background-color: @default-color;
    border-color: darken(@default-color, 20%);
    color: darken(@default-color, 70%);
}
.alert-primary {
    background-color: @primary-color;
    border-color: darken(@primary-color, 20%);
    color: #fff;
}
.alert-success {
    background-color: @palette-01;
    border-color: darken(@palette-01, 20%);
    color: #fff;
}

.alert-info{
    background-color: @info-color;
    border-color: darken(@info-color, 20%);
    color: darken(@info-color, 70%);
}

.alert-caution {
    background-color: @caution-color;
    border-color: darken(@caution-color, 20%);
    color: darken(@caution-color, 50%);
}
.alert-warning {
    background-color: @warning-color;
    border-color: darken(@warning-color, 20%);
    color: #fff;
}

.alert-danger{
    background-color: @danger-color;
    border-color: darken(@danger-color, 20%);
    color: #fff;
}

// Home Page Styles

.our-work-blocks{
    .block_desc{
        .client-work-title{
            font-size:1.6em;
        }
        .project-category{
            font-size:1.4em;
            font-style: italic;
        }
    }
}

.copyright{
    padding:25px 0;
    background:#666;
    color:#999;
    p{
        padding:0px;
        margin:0px;
    }
}

.featured-sponsor{
    padding-top: 25px;
    background: #eee;
}
.ad-units{
    margin-top:25px;

}
.ad-informer{
    text-align:center;
    font-size:10px;
    color:#999;
    border-top:1px solid #ccc;
    border-left:1px solid #ccc;
    border-right:1px solid #ccc;
    margin-bottom:5px;
}
.ad-unit{
    margin-bottom:15px;
    text-align:center;
    a{
    }
}

.sidebar-ad{
    text-align:center;
    margin-bottom:15px;
}

.description_block{
    h3{
        background: @palette-01;
        margin:0px;
        padding:5px;
        color: @palette-06;
        font-size:1.2em;
    }
    .block_desc{
        padding-top:10px;
        font-size: 0.8em;
        line-height: 1.6em;
    }
    .btn-primary{
        background: @palette-01;
        color: @palette-06;
        border: darken(@palette-01, 20%);
        &:hover, &:focus{
            background: darken(@palette-01, 10%);
        }
    }
}